<script lang="ts">
import { ComponentPublicInstance, defineComponent, ref } from 'vue'

export type PreloaderPublicInstance = ComponentPublicInstance & {
  visible: boolean
}

export default defineComponent({
  name: 'ThePreloader',
  setup (props, { expose }) {
    const publicInstance = {
      visible: ref(true)
    }
    expose(publicInstance)
    return publicInstance
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition>
      <div v-if="visible" id="app-loading">
        <svg class="spinner" viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="2"></circle>
        </svg>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
}

.spinner {
  position: absolute;
  z-index: 2;
  bottom: calc(vh(24px, $h-mobile) + 6px);
  left: 50%;
  width: 26px;
  height: 26px;
  margin: -13px 0 0 -13px;
  animation: rotate 2s linear infinite;

  .path {
    animation: dash 1.5s ease-in-out infinite;
    stroke: #fff;
    stroke-linecap: round;
  }

  @screen lg {
    bottom: calc(vh(48px) + 12px);
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
